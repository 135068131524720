import axios from 'axios';
import { Version } from '../context/enums';
import { getBackendHost } from '../lib/config';
import { dateStr2UTCDateTime } from '../lib/tools/datetime';

axios.defaults.withCredentials = true;


const URL = `${getBackendHost()}/v1/la/`
const axiosInstance = axios.create({
    baseURL: URL
});


export async function logout() {
    return axiosInstance.post("logout");
}

export async function getUserLeads(id, listId, search, status, limit, offset, departement, minAge, maxAge, jobCategory) {
    try {
        const json = JSON.stringify({
            search,
            list_id: listId,
            status,
            limit,
            offset,
            departement,
            min_age: minAge,
            max_age: maxAge,
            job_category: jobCategory
        });
        const res = await axiosInstance.post("get/user/leads/" + id, json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getCompanyLeads(companyId, listId, search, status, limit, offset, departement, minAge, maxAge, jobCategory) {
    const json = JSON.stringify({
        company_id: companyId,
        search,
        list_id: listId,
        status,
        limit,
        offset,
        departement,
        min_age: minAge,
        max_age: maxAge,
        job_category: jobCategory
    });
    return axiosInstance.put("get/company/leads", json,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
}

export async function getUserLeadsCount(userId) {
    try {
        const res = await axiosInstance.get("get/user/leads/count/" + userId);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getCompanyNRP(companyId) {
    try {
        const json = JSON.stringify({ company_id: companyId });
        const res = await axiosInstance.put("get/company/nrp", json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function clearNRP(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("clear/nrp", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getNRPCount(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/nrp/count", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllCount(id) {
    try {
        const res = await axiosInstance.post("all/count/manager/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getAllCompanyLeadsByStatus(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/all/company/leads/by/status", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllCountUser(id) {
    try {
        const res = await axiosInstance.post("all/count/user/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getAvailableLeadsCountByDepartement(json) {
    return axiosInstance.put("get/available/leads/count/by/departement", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updateLead(leadId, json) {
    return await axiosInstance.post("update/lead/" + leadId, json,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

export async function handleNRP(leadId, userId) {
    const json = JSON.stringify({ user_id: userId });
    return await axiosInstance.post("handle/nrp/" + leadId, json,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

export async function assignLeads(json) {
    return axiosInstance.put("assign/leads", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}


export async function removeLeads(id) {
    try {
        const res = await axiosInstance.post("remove/leads/" + id,);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getCollaborators(id) {
    try {
        const res = await axiosInstance.get("collaborators/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function addWorker(firstname, lastname, email, password, companyId, userType, office) {
    const json = JSON.stringify({
        firstname,
        lastname,
        email,
        password,
        user_type: userType,
        company_id: companyId,
        office
    });

    return axiosInstance.post("add/user", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updatePassword(email, password) {
    const json = JSON.stringify({
        email,
        password
    });

    return axiosInstance.post("update/password", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyUsers(companyId) {
    const json = JSON.stringify({ company_id: companyId });

    return axiosInstance.put("get/company/users", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getActiveRecentlyCompanyUsers() {
    return axiosInstance.put("get/active/recentlycompany/users", {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllLists() {
    return axiosInstance.get("get/all/list");
}

export async function getAllListsAccessibles(companyId) {
    const json = JSON.stringify({ company_id: companyId });

    return axiosInstance.put("get/list/accessibles", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllBienLeadsPhones() {
    return axiosInstance.get("get/all/bien/leads/phones");
}

export async function getAllCompanies() {
    return axiosInstance.get("get/all/companies");
}

export async function getActiveCompanies() {
    return axiosInstance.get("get/active/companies");
}

export async function getCompanyMetrics(companyId, duration, job) {
    const json = JSON.stringify({ company_id: companyId, duration, job });

    return axiosInstance.put("get/company/metrics", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyUsersMetrics(companyId, duration, job) {
    const json = JSON.stringify({ company_id: companyId, duration, job });

    return axiosInstance.put("get/company/user/metrics", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyLeadsCountSplit(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/company/leads/count/split", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyLeadsCount(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/company/leads/count", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getUnassignedCompanyLeadsCount(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/unassigned/company/leads/count", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updateCompanyActivity(companyId, activity) {
    const json = JSON.stringify({ company_id: companyId, activity });
    return axiosInstance.put("update/company/activity", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function attributesSalesman(leadId, salesmanId) {
    const json = JSON.stringify({ lead_id: leadId, salesman_id: salesmanId });
    return axiosInstance.put("attributes/salesman", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function storeFeatureUsage(userId, feature, details) {
    const json = JSON.stringify({ user_id: userId, feature, details });
    return axiosInstance.post("store/feature/usage", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getFeatureUsage(feature, companyId, duration) {
    const json = JSON.stringify({ feature, company_id: companyId, duration });
    return axiosInstance.put("get/feature/usage", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllJobCategories() {
    return axiosInstance.get("get/all/job/categories", {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function resetManyNRP() {
    return axiosInstance.put("reset/many/nrp", {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updateSoftwareVersion(version, adminVersion) {
    const json = { version, admin_version: adminVersion };
    return axiosInstance.put("update/software/version", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getSoftwareVersion() {
    return axiosInstance.get("get/software/version", {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAPECodes(query) {
    return axiosInstance.post("ia/get/ape/codes", { query }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getPopups(userId) {
    console.log("getPopups() called");
    const json = { user_id: userId };
    return axiosInstance.put("get/popups", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function handlePopupSeen(userId, popupName) {
    const json = { user_id: userId, name: popupName };
    return axiosInstance.put("handle/popup/seen", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function authenticate(loggedInCallback, finalCallback) {
    /*
        callback called with object containing all user info object when authenticated, if no do nothing
    */

    const json = { last_version_used: Version };
    return axiosInstance.put("authenticate", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        console.log('authenticate() data :>> ', response.data);
        let loggedIn = response.data.loggedIn;

        if (loggedIn === true) {
            return loggedInCallback(response.data.user);
        }
    }).catch(error => {
        console.error(error);
    }).then(finalCallback);
}

export async function uploadLeads(companyId, companyNickname, listId, file, fileParamName) {
    const formData = new FormData();
    formData.append(fileParamName, file);
    formData.append("company_id", companyId);
    formData.append("company_nickname", companyNickname);
    formData.append("list_id", listId);

    return axiosInstance.put('upload/leads', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch(error => {
        console.error(error);
    });
}

export async function addReminder(userId, leadId, reminderDate, hour, minute, reason) {
    const json = { user_id: userId, lead_id: leadId, reminder_date: reminderDate, hour, minute, reason };
    return axiosInstance.post("reminder/add", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getUserDailyReminders(userId) {
    const json = { user_id: userId };
    return axiosInstance.put("reminder/get/user/daily/reminders", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(data => {
        return data.data.map(row => { return { ...row, reminder_date: dateStr2UTCDateTime(row.reminder_date) } });
    });
}

export async function setAsTriggered(reminderId) {
    const json = { reminder_id: reminderId };
    return axiosInstance.post("reminder/set/as/triggered", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
