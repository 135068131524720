import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './context/AuthProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import './style/normalize.css'; // order of css imported for webflow: normalize, webflow, invests-***
import './style/webflow.css';
import './style/investis-fr-935b2255af2d0-a0dba7ef0cf18.webflow.css';
import './style/fix_webflow.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// PAGES
import Admin from './admin.js';
import { PrivateRoute } from './component/privateRoute.js';
import Compta from './Compta.js';
import Formation from './Formation.js';
import Login from './Login.js';
import MainPage from './Main.js';
import Software from './Software.js';
import TestChatbotPage from './TestChatbot.js';
import TestCalendar from './testCalendar.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute> <MainPage /> </PrivateRoute>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <PrivateRoute> <Admin /> </PrivateRoute>,
  },
  {
    path: "/comptabilite",
    element: <PrivateRoute> <Compta /> </PrivateRoute>,
  },
  {
    path: "/formation",
    element: <PrivateRoute> <Formation /> </PrivateRoute>,
  },
  {
    path: "/software",
    element: <PrivateRoute> <Software /> </PrivateRoute>,
  },
  {
    path: "/chatbot",
    element: <PrivateRoute> <TestChatbotPage /> </PrivateRoute>,
  },
  {
    path: "/calendar",
    element: <PrivateRoute> <TestCalendar /> </PrivateRoute>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
