const requestPermissionIfNeedBe = async (successCallBack) => {
    if (Notification.permission !== 'granted') {
        console.log("request noficiations permission");
        Notification.requestPermission().then(newPermission => {
            if (newPermission === 'granted') {
                successCallBack();
            }
        });
    }
}

const addNotification = (title, validateText) => {
    const notification = new Notification(title, {
        body: validateText,
        // icon: '/path/to/your/icon.png' // Optional
    });

    notification.onclick = () => {
        console.log('Notification clicked!');
    };
};

module.exports = {
    requestPermissionIfNeedBe,
    addNotification,
};
