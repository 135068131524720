import React, { useContext } from 'react';

import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import Header from './header';

export const PrivateRoute = ({ children }) => {

    const { auth, wasAuthenticatedOnce } = useContext(AuthContext);

    if (wasAuthenticatedOnce && !auth?.email) {
        return <Navigate to="/login" />;
    } else if (!auth?.email) {
        return <div className="la_body">
            <section className="la_section-6">
                <Header />
            </section>
        </div>
    }

    return (
        <>
            {children}
        </>
    );
};