import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import React, { useState } from 'react';

// todo(2) ajouter une key qu'ils s'additionnent les uns par dessusl es autres, notamment quand on update statut il peut
// y en avoir pleins
export default function Toast({ text, severity, duration, setToast }) {
    /*
        severity in: success, info, warning, error
    */
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setToast(null);
    };

    return (
        <Snackbar open={open}
            autoHideDuration={duration || 5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity={severity} variant="filled">
                {text}
            </Alert>
        </Snackbar>
    );
}
