import React from "react";
import { isDefined } from "../../lib/tools/basics";
import Transfer2SalesmanNotification from "./confirmations/transfer2salesman";

const ConfirmNotification = ({ text, confirmCallback, setConfirmation, showYesButton, showNoButton, specialPopup, color, setToast }) => {
    const handleYes = () => {
        confirmCallback();
        setConfirmation(null);
    };

    const handleNo = () => {
        setConfirmation(null);
    };

    if (isDefined(specialPopup) && specialPopup.type === "transfer2Salesman") {
        return <Transfer2SalesmanNotification
            text={text}
            setConfirmation={setConfirmation}
            color={color}
            confirmCallback={confirmCallback}
            companyId={specialPopup.companyId}
            leadId={specialPopup.leadId}
            setToast={setToast}
        />;
    }

    return (
        <section className="la-popup-warning-shade">
            <div className="la_message-pop-up">
                <img src="./images/warning-filled-svgrepo-com-1.svg" loading="lazy" alt="" className="la_image-21" />
                <div className="la_text-block-47">{text}</div>
                <div className="la_holder-buttons">
                    {showYesButton ? <span className="la_button-5 w-button" onClick={handleYes}>oui</span> : null}
                    {showNoButton ? <span className="la_button-5 non w-button" onClick={handleNo}>non</span> : null}
                </div>
            </div>
        </section>
    );
};

export default ConfirmNotification;
