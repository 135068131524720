import React, { useEffect, useState } from "react";
import { attributesSalesman, getActiveRecentlyCompanyUsers } from "../../../api/axios";


const Transfer2SalesmanNotification = ({ text, setConfirmation, companyId, leadId, color, confirmCallback, setToast }) => {
    const [collaborators, setCollaborators] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState("__undefined__");

    useEffect(() => {
        getActiveRecentlyCompanyUsers().then(res => setCollaborators(res.data));
    }, [companyId]);

    const handleFinished = () => {
        setConfirmation(null);
    };

    function getCollabdisplayString(collab) {
        let prefix = collab.office ? `[${collab.office.toUpperCase()}] ` : "";
        return `${prefix}${collab.lastname.toUpperCase()} ${collab.firstname}`;
    }

    const handleAttributeClick = () => {
        if (selectedCollaborator === "__undefined__") {
            return;
        }

        attributesSalesman(leadId, selectedCollaborator).then(() => {
            handleFinished();
            confirmCallback(selectedCollaborator);
            setToast({ text: "Lead transféré", severity: "success" });
        }).catch(err => {
            console.error(err);
            setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
        });
    }

    return (
        <section className="la_transfer_lead_popup">
            <div className="la_pop-up-send-lead">
                <div className="la_text-block-40">Transférez ce lead à un vendeur</div>
                <div className="w-form">
                    {/* <form id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get" data-wf-page-id="675f1ef132e5dc68d38d697d" data-wf-element-id="3dcff431-4747-4d2d-b980-240aee5a015d"> */}
                    <select value={selectedCollaborator}
                        className="la_select-field w-select"
                        onChange={ev => setSelectedCollaborator(ev.target.value)}>
                        <option value="__undefined__">Séléctionnez un collaborateur</option>
                        {collaborators.map((collab) => {
                            return <option key={collab.id} value={collab.id}>{getCollabdisplayString(collab)}</option>;
                        })}
                    </select>
                    {/* </form> */}
                </div>
                <div className="la_button-2 w-button" onClick={handleAttributeClick}>Attribuer</div>
                <div className="la_close-button w-inline-block" onClick={handleFinished}>
                    <img src="./images/close-svgrepo-com-2-2-1.svg" loading="lazy" alt="" />
                </div>
            </div>
        </section>
    );
};

export default Transfer2SalesmanNotification;
