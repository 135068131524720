import { useEffect, useState } from "react";
import { getUserDailyReminders, setAsTriggered } from "../../api/axios";
import { timeDiffFromNowInSeconds } from "../tools/datetime";
import { addNotification, requestPermissionIfNeedBe } from "../tools/notifications";


export function useReminders({ auth }) {
    const [reminders, setReminders] = useState(null);
    console.log('reminders :>> ', reminders);

    async function reloadReminders(auth) {
        if (auth?.tmp_has_access_to_reminder !== true) {
            return;
        }

        getUserDailyReminders(auth.id).then((newReminders) => {
            setReminders(newReminders);

            requestPermissionIfNeedBe(() => {});
            for (const nextRm of newReminders) {
                if (nextRm.triggered === true) {
                    continue;
                }

                if (nextRm.hour === null) {
                    addNotification(`Rappel: ${nextRm.lead_firstname} ${nextRm.lead_lastname}`, nextRm.reason || "");
                    setAsTriggered(nextRm.id);
                } else {
                    const minutesOfTheDay = nextRm.minute ? nextRm.minute : 0;
                    const delayInSeconds = Math.max(timeDiffFromNowInSeconds(nextRm.hour, minutesOfTheDay), 0);
                    console.log('delayInSeconds :>> ', delayInSeconds);

                    setTimeout(() => {
                        addNotification(`Rappel: ${nextRm.lead_firstname} ${nextRm.lead_lastname}`, nextRm.reason || "");
                        setAsTriggered(nextRm.id);
                    }, delayInSeconds * 1000);
                }
            }
        });
    }

    useEffect(() => {
        if (!auth?.id) {
            return;
        }

        reloadReminders(auth);
        const intervalId = setInterval(() => {
            reloadReminders(auth);
        }, 21600000); // Update 6 hours

        return () => clearInterval(intervalId);
    }, [auth]);

    return [reminders, reloadReminders];
};
