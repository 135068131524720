import { Button, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getAllCompanies, getCompanyLeadsCount, getCompanyLeadsCountSplit, getCompanyUsers, getUnassignedCompanyLeadsCount, updateCompanyActivity } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import FeaturesUsage from "./featuresUsage";
import GraphUsersWork from "./graphs/graph_users_work";
import TableUsersUsage from "./tables/table_users_usage";
import TableDaysUsage from "./tables/tables_days_usage";
import { useDaysUsage } from "./userDaysUsage";
import { useUsersUsage } from "./userUsersUsage";


export default function CustomersUsage({ setToast }) {
    const { auth, color } = useContext(AuthContext);

    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState("__undefined__");
    const [users, setUsers] = useState(null);

    const [duration, setDuration] = useState(14);
    const [job, setJob] = useState("__undefined__");

    const [leadsCount, setLeadsCount] = useState(null);
    const [daysUsageData] = useDaysUsage({ companyId, duration, job });
    const [usersUsageData] = useUsersUsage({ companyId, duration, job });

    const [totalCount, setTotalCount] = useState(0);
    const [notAssignedCount, setNotAssignedCount] = useState(0);

    const [displayGivenLeads, setDisplayGivenLeads] = useState(false);

    function loadCompanies() {
        getAllCompanies().then(data => {
            setCompanies(data.data);
        });
    }

    useEffect(() => {
        loadCompanies();
    }, []);

    useEffect(() => {
        if (companyId === "__undefined__") {
            return;
        }

        setTotalCount(0);
        setNotAssignedCount(0);
        getCompanyLeadsCount(companyId).then((res) => setTotalCount(res.data));
        getUnassignedCompanyLeadsCount(companyId).then((res) => setNotAssignedCount(res.data));
    }, [auth, companyId]);

    useEffect(() => {
        if (companyId === "__undefined__") {
            return;
        }

        setUsers(null);
        setLeadsCount(null);
        getCompanyUsers(companyId).then(data => setUsers(data.data));
        getCompanyLeadsCountSplit(companyId).then(data => {
            setLeadsCount(data.data);
        });
    }, [companyId]);

    if (!auth || !auth.email.endsWith('lead-access.fr')) {
        return null;
    }

    const getTableLeadsCount = () => {
        if (!leadsCount) {
            return null;
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Liste</TableCell>
                            <TableCell>Source de donnée</TableCell>
                            <TableCell>Nombre de leads</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leadsCount.map((row) => (
                            <TableRow key={row.list_name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{row.list_name}</TableCell>
                                <TableCell>{row.data_source}</TableCell>
                                <TableCell>{row.leads_count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    const handleChangeActivity = (newActivity) => {
        updateCompanyActivity(companyId, newActivity)
            .then(() => {
                loadCompanies();
                setToast({ text: "Activité mises à jour", severity: "success" });
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err);
            });
    };

    const getDataComponent = () => {
        if (companyId === "__undefined__") {
            return null;
        }
        const companiesFound = companies.filter(nextCpny => nextCpny.id === companyId);
        if (companiesFound.length === 0) {
            return null;
        }

        const company = companiesFound[0];
        const activityText = company.is_active ? "L'entreprise est active ✅" : "L'entreprise est inactive ❌";
        const activityUpdateText = company.is_active ? "Désactiver l'entreprise" : "Activer l'entreprise";

        return <>
            <Grid item xs={12}>
                <InputLabel>Sélectionnez une durée:</InputLabel>
                <Select value={duration} onChange={ev => setDuration(ev.target.value)}>
                    <MenuItem value={14}>2 semaines</MenuItem>
                    <MenuItem value={1}>1 jour</MenuItem>
                    <MenuItem value={2}>2 jours</MenuItem>
                    <MenuItem value={3}>3 jours</MenuItem>
                    <MenuItem value={4}>4 jours</MenuItem>
                    <MenuItem value={5}>5 jours</MenuItem>
                    <MenuItem value={6}>6 jours</MenuItem>
                    <MenuItem value={7}>1 semaine</MenuItem>
                    <MenuItem value={21}>3 semaines</MenuItem>
                    <MenuItem value={30}>1 mois</MenuItem>
                    <MenuItem value={60}>2 mois</MenuItem>
                </Select>
                <br />
                <br />
            </Grid>

            {/* todo(2) update here  use job categories instead !!! */}
            <Grid item xs={12} key="selectJob">
                <InputLabel>Sélectionnez un métier:</InputLabel>
                <Select value={job} onChange={ev => setJob(ev.target.value)}>
                    <MenuItem value="__undefined__">Tous les métiers</MenuItem>
                    <MenuItem value="Avocat">Avocat</MenuItem>
                </Select>
                <br />
                <br />
            </Grid>

            <Grid key="company_activity" item xs={12}>
                <h3 className="title" style={{ color }}>{"Etat d'activité"}</h3>
                <br />
                <p>{activityText}</p>
                <br />
                <Button onClick={() => handleChangeActivity(!company.is_active)} variant="contained" style={customButtonStyle}>
                    {activityUpdateText}
                </Button>
                <br />
                <br />
            </Grid>
            {users !== null ? <Grid key="onboarding" item xs={12}>
                <h3 className="title" style={{ color }}>{"Onboarding"}</h3>
                <br />
                <p>{`Nombre de comptes crées par l'entreprise: ${users.length}`}</p>
                <p>{`Nombre de total de leads fournis: ${totalCount}`}</p>
                <p>{`Nombre de total de leads non assignés: ${notAssignedCount}`}</p>
                <br />
            </Grid> : null}

            <Grid item xs={12} key="work_one">
                <h3 className="title" style={{ color }}>{"Leads utilisés par jour"}</h3>
                <GraphUsersWork daysUsageData={daysUsageData} />
            </Grid>

            <Grid item xs={12} key="prospecting_results_per_day">
                <h3 className="title" style={{ color }}>{"Résultats par jour"}</h3>
                <br />
                <TableDaysUsage daysUsageData={daysUsageData} />
                <br />
            </Grid>

            <Grid item xs={12} key="prospecting_results_per_user">
                <h3 className="title" style={{ color }}>{`Résultats par collaborateur (${duration} jours)`}</h3>
                <br />
                <TableUsersUsage usersUsageData={usersUsageData} duration={duration} />
                <br />
            </Grid>

            <Grid item xs={12} key="leads_count">
                <h3 className="title" style={{ color }}>{"Leads fournis"}</h3>
                <br />
                {!displayGivenLeads ? <Button variant="contained" onClick={() => setDisplayGivenLeads(true)} style={customButtonStyle}>
                    Afficher les leads
                </Button> : null}
                {displayGivenLeads ? <>
                    <ul style={{ listStylePosition: 'inside' }}>
                        <li><b>nico1</b> - first extract 600k contacts from previous job</li>
                        <li><b>medecin1</b> - 6k open source scraped</li>
                        <li><b>eric1</b> - 2k old jnn exchange database that still existed</li>
                        <li><b>nico2</b> - db 20k achete majoritairement restaurateur</li>
                        <li><b>nico2_ceo</b> - db 800 ceo</li>
                        <li><b>nico2_energy</b> - db 3k achete majoritairement B2B clients energie</li>
                        <li><b>nico3_biggy</b> - biggy enormous db 8 Millions</li>
                        <li><b>eric4_medecin_datagouv</b> - bases medecins data gouv</li>
                        <li><b>nico4_scrap_b2b</b> - annuaires avocats et medecins scrapes par freelance</li>
                        <li><b>nico5_appolo</b> - achat spreadsheet appolo</li>
                        <li><b>eric5_x</b> - leads échangés par api</li>
                    </ul>
                    <br />
                    {getTableLeadsCount()}
                </> : null}
                <br />
                <br />
                <br />
            </Grid>
        </>;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h1 className="title" style={{ color }}>{"Utilisation par client"}</h1>
            </Grid>

            <Grid item xs={12} key="select_company">
                <InputLabel>Sélectionnez une entreprise:</InputLabel>
                <Select value={companyId} onChange={ev => setCompanyId(ev.target.value)}>
                    <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                    {companies.filter((cpny) => cpny.nickname !== "la" && cpny.nickname !== "test").map((cpny, idx) => {
                        return <MenuItem key={cpny.id} value={cpny.id}>{cpny.name}</MenuItem>;
                    })}
                </Select>
                <br />
            </Grid>

            {getDataComponent()}

            <FeaturesUsage color={color} companies={companies} />
        </Grid>
    );
}
