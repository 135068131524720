import { useEffect, useState } from "react";
import { getAllLists } from "../../api/axios";
import { UserType } from "../../context/enums";


export function useDifferentLists({ auth, accessibleListIds, addAllLeadsList }) {
    const [listIdIdx, setListIdIdx] = useState(0);
    const [allLists, setAllLists] = useState(null);

    useEffect(() => {
        if (!auth) {
            return;
        }

        getAllLists().then((data) => {
            let result = [...data.data];

            if (addAllLeadsList && auth.user_type === UserType["MANAGER"]) {
                result.push({
                    id: "__all_company_leads__",
                    name: "Tous les leads de l'entreprise",
                    split_by_department: false,
                    nickname: "__all_company_leads__",
                });
            }
            setAllLists(result);
        });
    }, [auth]);

    const getAccessibleListsIds = () => {
        if (!auth) {
            return [1];
        }

        if (accessibleListIds === undefined || accessibleListIds === null) {
            return [1];
        }

        let result = [...accessibleListIds];

        if (addAllLeadsList && auth.user_type === UserType["MANAGER"]) {
            result.push("__all_company_leads__");
        }

        return result;
    }

    return [
        listIdIdx,
        getAccessibleListsIds,
        setListIdIdx,
        allLists
    ];
}
