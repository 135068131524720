import { createContext, useEffect, useState } from "react";
import { authenticate, getAllListsAccessibles } from "../api/axios";
import { AdminVersion, Version } from "./enums";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [wasAuthenticatedOnce, setWasAuthenticatedOnce] = useState(false);
    const [auth, setAuth] = useState(null);

    const handleAuth = () => {
        authenticate(setAuth, () => setWasAuthenticatedOnce(true));
    }

    useEffect(() => {
        console.log('Version :>> ', Version);
        console.log('AdminVersion :>> ', AdminVersion);
        handleAuth();
        const intervalId = setInterval(() => {
            handleAuth();
        }, 3600000); // Update every hour (3600000 milliseconds)

        return () => clearInterval(intervalId);
    }, []);

    const [listsIds, setListsIds] = useState(null);
    useEffect(() => {
        if (!auth || !auth.company_id) {
            return;
        }

        getAllListsAccessibles(auth.company_id).then((data) => {
            setListsIds(data.data);
        });
    }, [auth]);

    const [color, setColor] = useState(null);
    const [secondaryColor, setSecondaryColor] = useState(null);
    const [tertiaryColor, setTertiaryColor] = useState(null);
    const [webDomain, setWebdomain] = useState(null);
    const [logoURL, setLogoURL] = useState(null);
    const [isPreprod, setIsPreprod] = useState(false);

    useEffect(() => {
        const newWebDomain = window.location.hostname;
        setWebdomain(newWebDomain);

        if (newWebDomain === "app.alyor-call.fr") {
            // todo(1) mettre tout cela dans config
            // setColor("rgb(75, 166, 100)");
            // setSecondaryColor("rgb(247, 247, 245)");
            setLogoURL("./images/logo_alyor.svg");
            // setTertiaryColor("#24398A");
        } else {
            setLogoURL("./images/logo-lead-access-1-1.svg");
        }

        if (newWebDomain === "localhost") {
            setIsPreprod(true);
        }

        setColor("#24398A");
        setSecondaryColor("rgb(232, 240, 254)");
        setTertiaryColor("#731993");
    }, []);

    return (
        <AuthContext.Provider value={{
            auth,
            setAuth,
            listsIds,
            setListsIds,
            webDomain,
            color,
            secondaryColor,
            logoURL,
            tertiaryColor,
            isPreprod,
            wasAuthenticatedOnce
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;