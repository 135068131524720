import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { getAPECodes } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";


export default function TestChatbot({ setToast }) {
    const { auth, color } = useContext(AuthContext);

    const [jobQuery, setJobQuery] = useState("");
    const [apeCodes, setAPECodes] = useState(null);

    const handleUpdateVersion = () => {
        if (!jobQuery || jobQuery.length < 8) {
            setToast({ text: "Versions non définies", severity: "error" });
            return;
        }

        setAPECodes(null);
        getAPECodes(jobQuery)
            .then(data => {
                setAPECodes(data.data.ape_codes_list);
            }).catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err);
            });
        setToast({ text: "Versions mis à jour", severity: "success" });
    }

    const getAPECodesCompnent = () => {
        if (apeCodes === null) {
            return null;
        }

        return <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Code APE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apeCodes && apeCodes.map((row, idx) => {
                            return <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{row.ape_code_description}</TableCell>
                                <TableCell align="right">{row.ape_code}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>;
    }

    const getChatbotComponent = () => {

        return <>
            <Grid item xs={12}>
                <h3 className="title" style={{ color }}>Métiers</h3>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Décrivez les métiers souhaités"
                    value={jobQuery}
                    onChange={(ev) => setJobQuery(ev.target.value)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            <Grid item xs={12}>
                <Button onClick={handleUpdateVersion} variant="contained" style={customButtonStyle}>
                    Valider
                </Button>
            </Grid>
            {getAPECodesCompnent()}
        </>;
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    if (!auth || !auth.email.endsWith('lead-access.fr')) {
        return null;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h1 className="title" style={{ color }}>Lead Access Chatbot</h1>
            </Grid>


            {getChatbotComponent()}
        </Grid>
    );
}
