import React from 'react';


export default function PopupUpdateStatus({ validateCallback }) {
    return (
        <section className="la_congrats-pop-up">
            <div className="la_pop-up">
                <div className="la_title">🎉 Félicitations 🎉</div>
                <img src="./images/undraw_well_done_re_3hpo.svg" loading="lazy" alt="" className="la_image-19" />
                <div className="la_text-notification">Vous avez été séléctionné pour tester des améliorations 🏆</div>
                <div className="la_paragraph">Pour mesurer l'efficacité des nouveautés, pensez à bien mettre à jour les status de vos rendez-vous (présence /absence du client et vente si elle a lieu).</div>
                <div className="la_vertical_container_centered">
                    <div><strong className="la_bold-text">Objectif +50% de conversion</strong></div>
                    <div className="la_paragraph blank">Merci pour votre engagement 🌟</div>
                </div>
                <div className="la_button-2 w-button" onClick={validateCallback}>J'ai compris</div>
            </div>
        </section>
    );
}
