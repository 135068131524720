import { Button, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getSoftwareVersion, updateSoftwareVersion } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { AdminVersion, Version } from "../../context/enums";


export default function SoftwareManagement({ setToast }) {
    const { auth, color } = useContext(AuthContext);

    const [versionsLoaded, setVersionsLoaded] = useState(false);
    const [version, setVersion] = useState("");
    const [adminVersion, setAdminVersion] = useState("");

    useEffect(() => {
        getSoftwareVersion().then(data => {
            setVersionsLoaded(true);
            setVersion(data.data.version);
            setAdminVersion(data.data.admin_version);
        })
    }, []);

    const handleUpdateVersion = () => {
        if (!version || !adminVersion) {
            setToast({ text: "Versions non définies", severity: "error" });
            return;
        }

        updateSoftwareVersion(version, adminVersion)
            .then(() => {
                setToast({ text: "Versions mis à jour", severity: "success" });
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err);
            });
    }

    const getUpdateVersionComponent = () => {
        if (!versionsLoaded) {
            return null;
        }

        return <>
            <Grid item xs={12}>
                <h3 className="title" style={{ color }}>Mettre à jour version</h3>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={`Version (front est à ${Version})`}
                    value={version}
                    onChange={(ev) => setVersion(ev.target.value)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={`Admin Version (front est à ${AdminVersion})`}
                    value={adminVersion}
                    onChange={(ev) => setAdminVersion(ev.target.value)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            <Grid item xs={12}>
                <Button onClick={handleUpdateVersion} variant="contained" style={customButtonStyle}>
                    Mettre à jour
                </Button>
            </Grid>
        </>;
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    if (!auth || !auth.email.endsWith('lead-access.fr')) {
        return null;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h1 className="title" style={{ color }}>Logiciel</h1>
            </Grid>


            {getUpdateVersionComponent()}
        </Grid>
    );
}
