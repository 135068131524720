import { formatISO } from "date-fns";

export function simplifytimestring(timeString) {
    return timeString.slice(0, 5);
}

export function simplifyDateString(dateString) {
    return dateString.slice(0, 10);
}

export function dbTimeToUserTime(timeString) {
    return timeString.slice(0, 5).replace(/:/g, 'h');
}

export function getDayTimeStringFromDate(date) {
    /*
        :param date: Date object
        return: time of the day string, example "08:00:00"
        WARNING: it ignores seconds
    */
    return date.toISOString().slice(11, 16) + ":00";
}

export function areDatesSimilar(date1, date2) { // il y avait des bugs la desssus date changeait de 1 seconde etc..
    return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();
}

export function dateStr2UTCDateTime(dateString) {
    const dateParts = dateString.split("-");
    if (dateParts.length !== 3) {
        console.error("dateStr2UTCDateTime couldn't convert " + dateString);
        return;
    }

    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-based
    const day = parseInt(dateParts[2]);

    return new Date(Date.UTC(year, month, day));
}

export function getTodayUTC() {
    return new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
}

// _ temporary comment has changed was .toISOString() replaced with formatISO
export function date2str(date) {
    return simplifyDateString(date2dateStr(date));
}

export function date2dateStr(date) {
    /*
        return: a string, example: 2023-05-17T00:00:00.000Z in UTC format
    */
    return formatISO(date, { representation: "date" });
}

export function date2frenchString(date) {
    if (!date) {
        return null;
    }

    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    return date.toLocaleDateString('fr-FR', options).toUpperCase().replace('.', '');
}

export function datestr2frenchString(dateStr) {
    if (!dateStr) {
        return null;
    }

    return date2frenchString(dateStr2UTCDateTime(dateStr));
}

// import Holidays from "date-holidays";
//
// export function getHolidaysDates() {
//     /*
//         return: holidays list of UTC dates from last year to next year (both included)
//     */
//     const hd = new Holidays();
//     const currentYear = new Date().getFullYear();
//     const prevYear = currentYear - 1;
//     const nextYear = currentYear + 1;

//     const holidaysList = [];
//     for (let year of [prevYear, currentYear, nextYear]) {
//         hd.init('FR', 'FR', 'fr_FR');
//         const yearHolidays = hd.getHolidays(year);
//         for (let holiday of yearHolidays) {
//             holidaysList.push(holiday.start);
//         }
//     }

//     return holidaysList.map(holi => dateStr2UTCDateTime(date2dateStr(holi)));
// }

export function getCurrentWeekDates(offset) {
    /*
        :param offset: days count to offset today dates
    */
    const today = getTodayUTC();
    const startDate = new Date(today.setDate(today.getDate() - today.getDay() + offset));

    const weekDates = [];
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i);
        weekDates.push(currentDate);
    }

    return weekDates;
}

export function getFirstDaysOfMonths(monthsCount) {
    var currentDate = getTodayUTC();
    var firstDays = [];

    for (var i = 0; i < monthsCount; i++) {
        var year = currentDate.getUTCFullYear();
        var month = currentDate.getUTCMonth();
        var firstDay = new Date(Date.UTC(year, month, 1));
        firstDays.push(firstDay);

        currentDate.setUTCMonth(month + 1);
    }

    return firstDays;
}

export function getFrenchDayName(date) {
    const daysOfWeek = [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
    ];

    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
}

export function timeDiffFromNowInSeconds(hoursOfTheDay, minutesOfTheDay) {
    const now = new Date();

    const specialTime = new Date();
    specialTime.setHours(hoursOfTheDay, minutesOfTheDay, 0, 0);

    const diffInMilliseconds = specialTime - now;
    return Math.floor(diffInMilliseconds / 1000);
}

export function date2UTCDate(date) {
    return dateStr2UTCDateTime(date2dateStr(date));
}
