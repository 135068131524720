import {
    Grid,
    Paper,
    styled,
    Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import { openNewTab } from "../../lib/tools/browser";


/*
    Popup version spéciale uniquement pour Bienviyance
*/


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const ExternalToolsSelection = ({ handleClose, lead }) => {
    const { auth, color } = useContext(AuthContext);

    const [openedUrl, setOpenedUrl] = useState(null);  // null means no Iframe has been selected and displayed

    const getLeadFieldComponent = (title, value) => {
        return <div className="la_form-block-3 w-form">
            <div className="la_form-3">
                <div className="la_text-field-6 w-input">{value}</div>
                <div className="la_text-block-38">{title}</div>
            </div>
        </div>;
    }

    // todo(2) adopter type d'info afficher par rapport a lead b2c/nunu et aux infos disponibles
    if (openedUrl !== null) {
        return (
            <section className="la-360-bg-b">
                <div className="la_browser-div">
                    <div className="la_holder-contact-informations">
                        <div className="la_open-navigator w-inline-block">
                            <img src="./images/tab-new-svgrepo-com-1.svg" loading="lazy" alt="" className="la_image-17" />
                            <div className="la_text-block-36" onClick={() => doOpenNewTab(openedUrl)}>
                                Ouvrir dans un nouvel onglet
                            </div>
                        </div>
                        <div className="la_text-block-37">Informations du contact :</div>
                        <div className="la_contact-informations">
                            {getLeadFieldComponent("Nom", [lead.firstname, lead.lastname].filter(nn => nn).join(" "))}
                            {getLeadFieldComponent("Téléphone", lead.phone_number_concatenated)}
                            {getLeadFieldComponent("Email", lead.email)}
                            {getLeadFieldComponent("Ville", lead.city)}
                            {getLeadFieldComponent("Code postal", lead.zipcode)}
                            {getLeadFieldComponent("Adresse", lead.adresse)}
                            {getLeadFieldComponent("Année de naissance", lead.year_of_birth)}
                            {getLeadFieldComponent("Statut de résidence", lead.housing_status)}
                            {getLeadFieldComponent("Imposition", lead.income_tax)}
                        </div>
                    </div>
                    <div className="navigation-browser">
                        <iframe title='360-browser-frame' src={openedUrl} style={{ width: "100%", height: "100%" }}></iframe>
                    </div>
                    <div className="la_close-button left w-inline-block" onClick={handleClose}>
                        <img src="./images/close-svgrepo-com-2-2.svg" loading="lazy" alt="" className="la_image-18" />
                        <div className="la_text-block-39">FERMER</div>
                    </div>
                </div>
            </section>
        );
    }

    function doOpenNewTab(url) {
        openNewTab(url);
        setOpenedUrl(null);
        handleClose();
    }

    function getExternalTools() {
        if (auth.company_nickname === "bien" || auth.company_nickname === "bienstra") {
            return <div className="la_holder-partners">
                <div className="la_holder-partner w-inline-block" onClick={() => setOpenedUrl("https://bnvce.fr/affaires-nouvelles/")}>
                    <img src="https://app.lead-access.fr//images/bie/affaires_nouvelles.png" loading="lazy" sizes="88.296875px" alt="" className="la_image-16" />
                </div>
                <div className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://calendar.google.com/calendar/")}>
                    <img src="https://app.lead-access.fr//images/bie/google_calendar.png" loading="lazy" sizes="133.328125px" alt="" className="la_image-16" />
                </div>
                <div className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://ernest-conseil.fr/login")}>
                    <img src="https://app.lead-access.fr//images/bie/ernest_conseil.png" loading="lazy" alt="" className="la_image-16" />
                </div>
            </div>;
        } else {
            return <Grid item>
                <Typography sx={{ color: "white" }}>
                    Si vous souhaitez ajoutez des liens ici, contactez votre Manager.
                    <br />
                    <br />
                </Typography>
            </Grid>;
        }
    }

    function getProducts() {
        if (auth.company_nickname === "bien" || auth.company_nickname === "bienstra") {
            return <>
                <div className="la_holder-partners _2">
                    <div className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://www.ssogf.generali.fr/user/auth/dologin?tabId=1581612890")}>
                        <img src="./images/bie/generali.png" loading="lazy" sizes="180px" alt="" className="la_image-16 g" />
                    </div>
                    <div className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://espacecourtier.entoria.fr/login")}>
                        <img src="./images/bie/entoria.svg" loading="lazy" sizes="174.15625px" alt="" className="la_image-16" />
                    </div>
                    <div className="la_holder-partner w-inline-block" onClick={() => setOpenedUrl("https://www.swisslifeone.fr/index-swisslifeOne.html#/accueil")}>
                        <img src="./images/bie/swisslife.png" loading="lazy" sizes="157.765625px" alt="" className="la_image-16" />
                    </div>
                </div>
                <div className="la_holder-partners" onClick={() => doOpenNewTab("https://extranet.neoliane.fr/connection?lostpage=dashboard")}>
                    <div className="la_holder-partner w-inline-block">
                        <img src="./images/bie/neoliane.png" loading="lazy" alt="" className="la_image-16" />
                    </div>
                    <div className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://pro.alptis.org/auth/realms/alptis-distribution/protocol/openid-connect/auth?client_id=espace-distributeur&redirect_uri=https%3A%2F%2Fpro.alptis.org%2F&state=4e2ca98f-58db-40ff-a834-df6636312639&response_mode=query&response_type=code&scope=openid&nonce=002c1ddf-a031-4016-afa0-b1c40658a198")}>
                        <img src='./images/bie/alptis.png' loading="lazy" sizes="149.6875px" alt="" className="la_image-16" />
                    </div>
                    <div className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://soizic.zenioo.com/ZN_Th/Login")}>
                        <img src="./images/bie/zenioo.png" loading="lazy" sizes="142.125px" alt="" className="la_image-16" />
                    </div>
                </div>
            </>;
        } else {
            return <Grid item>
                <Typography sx={{ color: "white" }}>
                    Si vous souhaitez ajoutez des liens ici, contactez votre Manager.
                    <br />
                    <br />
                </Typography>
            </Grid>;
        }
    }

    if (!auth || !color) {
        return null;
    }

    return <section className="la-360-man-b">
        <div className="la_holder-outils">
            <div className="la_title-outils">
                <img src="./images/tools-svgrepo-com-1.svg" loading="lazy" alt="" className="la_image-15" />
                <div className="la_text-block-33">Outils Interne</div>
            </div>
            {getExternalTools()}

            <div className="la_title-outils">
                <img src="./images/all-inbox-svgrepo-com-1.svg" loading="lazy" alt="" className="la_image-15" />
                <div className="la_text-block-33">Produits</div>
            </div>
            {getProducts()}

            <div className="la_title-outils">
                <img src="./images/cube-free-material-2-svgrepo-com-1.svg" loading="lazy" alt="" className="la_image-15" />
                <div className="la_text-block-33">Simulations</div>
            </div>

            <div className="la_holder-partners _0">
                <span className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://www.boursorama.com/patrimoine/impots/simulateur/impot-revenu")}>
                    <div className="la_text-block-34">Impots sur le revenu</div>
                </span>
                <span className="la_holder-partner w-inline-block" onClick={() => setOpenedUrl("https://www.meilleurtaux.com/credit-immobilier/simulation-de-pret-immobilier/calcul-des-mensualites.html")}>
                    <div className="la_text-block-34">Crédit Immobilier</div>
                </span>
                <span className="la_holder-partner w-inline-block" onClick={() => doOpenNewTab("https://www.boursorama.com/patrimoine/retraite/simulateur-plan-epargne-retraite-per")}>
                    <div className="la_text-block-34">PER</div>
                </span>
            </div>

            <div className="la_close-button w-inline-block" onClick={handleClose}>
                <div className="la_text-block-35">FERMER</div>
                <img src="./images/close-svgrepo-com-2-2.svg" loading="lazy" alt="" />
            </div>
        </div>
    </section>;
};

export default ExternalToolsSelection;
