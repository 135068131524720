import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPopups, handlePopupSeen } from '../../api/axios';
import AuthContext from '../../context/AuthProvider';
import { addNotification, requestPermissionIfNeedBe } from '../../lib/tools/notifications';
import PopupMessage from './popup_message';
import PopupUpdateStatus from './popup_update_statuts';


export default function Popup() {
    const { auth } = useContext(AuthContext);

    const location = useLocation();

    const [popups, setPopups] = useState(null);

    function loadPopups(auth) {
        getPopups(auth.id).then(data => {
            setPopups(data.data);
        });
    }

    useEffect(() => {
        if (!auth?.id) return;

        loadPopups(auth);
        const intervalId = setInterval(() => {
            loadPopups(auth);
        }, 3600000); // Update every hour (3600000 milliseconds)

        return () => clearInterval(intervalId);
    }, [auth]);

    const hideAndValidatePopup = (popupName) => {
        let newPopups = null;
        if (popups !== null) {
            newPopups = popups.filter(po => po !== popupName);
        }

        setPopups(newPopups);
        handlePopupSeen(auth.id, popupName);
    }

    const searchParams = new URLSearchParams(location.search);
    const isDebug = searchParams.get('debug');

    if (isDebug === "true") {
        return null;
    }

    if (popups === null || popups.length === 0) {
        return null
    }

    const popup = popups[0];

    if (popup === "tmp_popup_new_pagination") {
        const title = "Nouvelle navigation des leads";
        const text = `La navigation entre les leads se fait désormais à travers des pages. 
            Pour passer à la page suivante, veuillez utiliser les boutons avec les flèches gauche et droite comme sur l'image ci-dessus.`;
        const imgSrc = "images/functionalities/arrows.png";
        return <PopupMessage title={title}
            texts={[text]}
            imgSrc={imgSrc}
            imgClass={undefined}
            validateText="J'ai compris"
            validateCallback={() => hideAndValidatePopup(popup)} />;
    } else if (popup === "transfer_to_salesman") {
        const title = "Transférer un lead à un vendeur";
        const text = `Dès qu'un rendez-vous est pris, il est désormais possible de transférer un Lead à un vendeur en 1 click, 
            grâce au bouton au milieu ci-dessus.`;
        const imgSrc = "images/functionalities/transfer_to_salesman.png";
        return <PopupMessage title={title}
            texts={[text]}
            imgSrc={imgSrc}
            imgClass={undefined}
            validateText="J'ai compris"
            validateCallback={() => hideAndValidatePopup(popup)} />;
    } else if (popup === "360") {
        const title = "360 - Gagnez du temps !";
        const text = `Vous pouvez accéder à tous vos outils ainsi qu'à des simulateurs en 1 click grâce au bouton ci-dessus à droite.
        Certains de ces outils sont même accessibles dans le même onglet avec les informations du Lead à gauche !`;
        const imgSrc = "images/functionalities/360.png";
        return <PopupMessage title={title}
            texts={[text]}
            imgSrc={imgSrc}
            imgClass={undefined}
            validateText="J'ai compris"
            validateCallback={() => hideAndValidatePopup(popup)} />;
    } else if (popup === "copy_paste") {
        const title = "Copiez les informations du leads";
        const text = `Vous pouvez en 1 click copier toutes les informations du leads en cliquant sur le bouton du milieu ci-dessus. 
        Collez les où vous le souhaitez !`;
        const imgSrc = "images/functionalities/copy_paste.png";
        return <PopupMessage title={title}
            texts={[text]}
            imgSrc={imgSrc}
            imgClass={undefined}
            validateText="J'ai compris"
            validateCallback={() => hideAndValidatePopup(popup)} />;
    } else if (popup === "b2b_medical_augmented") {
        const title = "B2B médical";
        const text = `Tous nos leads concernant le B2B médical sont enrichis de la source de donnée,
            et nombre d'entre eux également de la date de début d'activité du profesionnel.`;
        const imgSrc = "images/functionalities/b2b_medical_augmented.png";
        return <PopupMessage title={title}
            texts={[text]}
            imgSrc={imgSrc}
            imgClass={undefined}
            validateText="J'ai compris"
            validateCallback={() => hideAndValidatePopup(popup)} />;
    } else if (popup === "reminders") {
        const title = "Rappels";
        const texts = [`Il vous est désormais possible de définir des rappels afin par exemple de recontacter un lead
            à un moment précis.`,
            `Le bouton à gauche sert à ajouter un rappel.
            Pour que ce système fonctionne, veuillez autoriser les notifications (une demande doit apparaître maintenant).`];
        const imgSrc = "images/functionalities/reminders.png";
        requestPermissionIfNeedBe(() => addNotification("Test", "Notification test"));
        addNotification("Test", "Première notification test");
        return <PopupMessage title={title}
            texts={texts}
            imgSrc={imgSrc}
            imgClass={undefined}
            validateText="J'ai compris et j'authorise les notifications"
            validateCallback={() => hideAndValidatePopup(popup)} />;
    } else if (popup === "dont_forget_to_update_statuts") {
        return <PopupUpdateStatus validateCallback={() => hideAndValidatePopup(popup)} />;
    }
}
