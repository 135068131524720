import React from 'react';


export default function PopupMessage({ title, imgSrc, imgClass, texts, validateText, validateCallback }) {
    const imgRealClass = imgClass ? imgClass : "la_image-20";
    
    return (
        <section className="la_nouvelle-fonctionalit">
            <div className="la_tuto-fonctionalit">
                <div className="la_tb45">{title}</div>
                {imgSrc ? <img src={imgSrc} loading="lazy" alt="" className={imgRealClass} /> : null}
                {texts.map((txt, idx) => {
                    return <div key={idx} className="la_text-block-46">{txt}</div>;
                })}
                <div className="la_button-2 w-button" onClick={validateCallback}>{validateText}</div>
            </div>
        </section>
    );
}
