import { useEffect, useState } from "react";
import { getAllJobCategories } from "../../api/axios";


export function useJobCategories({ auth }) {
    const [jobCategories, setJobCategories] = useState([]);

    useEffect(() => {
        if (!auth) {
            return;
        }

        getAllJobCategories().then((data) => {
            setJobCategories(data.data);
        });
    }, [auth]);

    return jobCategories;
}
