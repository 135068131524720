import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { LeadStatus } from "../../../context/enums";
import { sumArray } from "../../../lib/tools/numbers";


const DISPLAY_WORK_PER_DAY_PER_USER = false; // todo(2) remove deactivated users


export default function TableUsersUsage({ usersUsageData, duration }) {
    if (!usersUsageData) {
        return null;
    }

    const usersCount = usersUsageData.length;

    const sumWorkDone = sumArray(usersUsageData.map((row) => row.work_done));
    const sumRdvBooked = sumArray(usersUsageData.map((row) => row.rdv_booked));
    const sumRdvTaken = sumArray(usersUsageData.map((row) => row.rdv_taken));
    const sumSignings = sumArray(usersUsageData.map((row) => row.signings));

    const percentageRdvBooked = (sumRdvBooked / sumWorkDone * 100).toFixed(1);
    const percentageRdvTaken = (sumRdvTaken / sumWorkDone * 100).toFixed(1);
    const percentageSigning = (sumSignings / sumWorkDone * 100).toFixed(1);

    function getWorkPerUserPerDayString(usersCount, sumWorkDone) {
        if (!DISPLAY_WORK_PER_DAY_PER_USER || usersCount === 0 || duration < 10) {
            return "";
        }

        const monthWorkNormalisation = 19 / 30;
        const result = (sumWorkDone / usersCount / duration * monthWorkNormalisation).toFixed(0);
        return ` (${result} / u / jour)`;
    }

    const workPerUserPerDayStr = getWorkPerUserPerDayString(usersCount, sumWorkDone);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell align="right">Leads travaillés</TableCell>
                        <TableCell align="right">{LeadStatus["RDV_PRIS"]}</TableCell>
                        <TableCell align="right">{LeadStatus["RDV_HAPPENED"]}</TableCell>
                        <TableCell align="right">{LeadStatus["SIGNE"]}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersUsageData.map((row) => {
                        let displayName = row.fullname;
                        if (row.user_type === "Manager") {
                            displayName += " (M)"
                        }
                        return <TableRow key={displayName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{displayName}</TableCell>
                            <TableCell align="right">{row.work_done}</TableCell>
                            <TableCell align="right">{row.rdv_booked}</TableCell>
                            <TableCell align="right">{row.rdv_taken}</TableCell>
                            <TableCell align="right">{row.signings}</TableCell>
                        </TableRow>;
                    }).concat(sumWorkDone > 0 ? [
                        <TableRow key={"__sum__"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Total</TableCell>
                            <TableCell align="right">{`${sumWorkDone}${workPerUserPerDayStr}`}</TableCell>
                            <TableCell align="right">{`${sumRdvBooked} (${percentageRdvBooked}%)`}</TableCell>
                            <TableCell align="right">{`${sumRdvTaken} (${percentageRdvTaken}%)`}</TableCell>
                            <TableCell align="right">{`${sumSignings} (${percentageSigning}%)`}</TableCell>
                        </TableRow>
                    ] : [])}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
