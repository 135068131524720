import React, { useContext, useEffect, useState } from 'react';
import { getAllCompanyLeadsByStatus, getAllCountUser, getCompanyLeads, getCompanyLeadsCount, getUserLeads, getUserLeadsCount, storeFeatureUsage } from './api/axios';
import ExternalToolsSelection from './component/360/external_tools_selection';
import Header from './component/header';
import Lead from './component/lead';
import Popup from './component/popups/popup';
import PopupAddReminder from './component/popups/popup_add_reminder';
import ConfirmNotification from './component/ux/confirmation';
import Toast from './component/ux/toast';
import AuthContext from './context/AuthProvider';
import { LeadStatus, ListsNicknames, StatusColor, UserType, Version } from './context/enums';
import { useDifferentLists } from './lib/leads/useDifferentLists';
import { useJobCategories } from './lib/leads/useJobCategories';
import { useReminders } from './lib/reminders/useReminders';
import { getIntegersInRange } from './lib/tools/numbers';
import { setDepartementInput, setNumericInput } from './lib/tools/strings';

function MainPage() {

  const { auth, listsIds, color, isPreprod, wasAuthenticatedOnce } = useContext(AuthContext);

  const [reminders, reloadReminders] = useReminders({ auth });

  const [toast, setToast] = useState(null);
  const [popupCreateReminderLeadId, setPopupCreateReminderLeadId] = useState(null);
  const [confirmation, setConfirmation] = useState(null);

  const [unique, setUnique] = useState(false);

  const [input, setInput] = useState("");
  const [statut, setStatut] = useState("");
  const [departement, setDepartement] = useState("");
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [jobCategory, setJobCategory] = useState('__undefined__');
  const [index, setIndex] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [leads, setLeads] = useState([]);
  const [statutCount, setStatutCount] = useState([]);

  const [itemParPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPageFetched, setMaxPageFetched] = useState(-1);

  const [expandFilters, setExpandFilters] = useState(false);

  const [displayExternalTool, setDisplayExternalTool] = useState(false);
  const [externalToolLead, setExternalToolLead] = useState(null);

  const jobCategories = useJobCategories({ auth });

  const [listIdIdx, getAccessibleListsIds, setListIdIdx, allLists] = useDifferentLists({ auth, accessibleListIds: listsIds, addAllLeadsList: true });

  const getListLeads = (listId, searchField, statusField, limit, offset, concatLead, departementField, minAgeField, maxAgeField, newJobCat) => {
    console.log('getLidsLeads called with :>> listId ', listId, "searchField", searchField,
      "statusField", statusField, "limit", limit, "offset", offset, "concatLead", concatLead,
      "departementField", departementField, "minAgeField", minAgeField, "maxAgeField", maxAgeField,
      "newJobCat", newJobCat);
    function processResult(res) {
      if (concatLead) {
        setLeads([...leads, ...res.data]);
      } else {
        setLeads(res.data);
      }
    }

    const jobCatCleaned = newJobCat === "__undefined__" ? null : newJobCat;
    if (listId === "__all_company_leads__") {
      getCompanyLeads(auth.company_id, null, searchField, statusField, limit, offset, departementField, minAgeField, maxAgeField, jobCatCleaned)
        .then(processResult);
    } else {
      getUserLeads(auth.id, listId, searchField, statusField, limit, offset, departementField, minAgeField, maxAgeField, jobCatCleaned)
        .then(processResult);
    }
  }

  const fetchPageIfNeedBe = (pageIdx) => {
    if (pageIdx !== maxPageFetched + 1) {
      return;
    }

    console.log("fetching page ", pageIdx, "itemParPage", itemParPage);
    getListLeads(getAccessibleListsIds()[listIdIdx],
      input,
      statut,
      itemParPage,
      pageIdx * itemParPage,
      true,
      departement,
      minAge,
      maxAge,
      jobCategory);
    setMaxPageFetched(maxPageFetched + 1);
  }

  const setup = () => {
    if (!auth || listsIds === null) {
      return;
    }

    if (auth.user_type === UserType.MANAGER) {
      getAllCompanyLeadsByStatus(auth.company_id).then((res) => { setStatutCount(res.data) });
      getCompanyLeadsCount(auth.company_id).then((res) => setTotalCount(res.data));
    } else {
      getAllCountUser(auth.id).then((res) => { setStatutCount(res.data) });
      getUserLeadsCount(auth.id).then((res) => setTotalCount(res.data));
    }

    getListLeads(getAccessibleListsIds()[listIdIdx],
      input,
      statut,
      itemParPage,
      currentPage * itemParPage,
      false,
      departement,
      minAge,
      maxAge,
      jobCategory);
    setMaxPageFetched(0);
  }

  const handleMaxAgeFilterChange = (e) => {
    let maxAgeField = e.target.value;

    setCurrentPage(0);
    setIndex(0);
    setLeads([]);
    setNumericInput(e, setMaxAge);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, minAge, maxAgeField, jobCategory);
    setMaxPageFetched(0);
  }

  const handleMinAgeFilterChange = (e) => {
    let minAgeField = e.target.value;

    setCurrentPage(0);
    setIndex(0);
    setLeads([]);
    setNumericInput(e, setMinAge);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, minAgeField, maxAge, jobCategory);
    setMaxPageFetched(0);
  }

  const handleDepartementFilterChange = (e) => {
    let departementField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setDepartementInput(e, setDepartement);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departementField, minAge, maxAge, jobCategory);
    setMaxPageFetched(0);
  }

  const handleSearchChange = (e) => {
    let searchField = e.target.value;

    setCurrentPage(0);
    setIndex(0);
    setLeads([]);
    setInput(searchField);
    getListLeads(getAccessibleListsIds()[listIdIdx], searchField, statut, itemParPage, 0, false, departement, minAge, maxAge, jobCategory);
    setMaxPageFetched(0);
  }

  const handleStatusChange = (e) => {
    let statusField = e.target.value;

    setCurrentPage(0);
    setIndex(0);
    setLeads([]);
    setStatut(statusField);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statusField, itemParPage, 0, false, departement, minAge, maxAge, jobCategory);
    setMaxPageFetched(0);
  }

  const handleJobCategoryChange = (e) => {
    let statusField = e.target.value;

    setCurrentPage(0);
    setIndex(0);
    setLeads([]);
    setJobCategory(statusField);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, minAge, maxAge, statusField, jobCategory);
    setMaxPageFetched(0);
  }

  const handleListIdChange = (newViewIdx) => {
    let listId = getAccessibleListsIds()[newViewIdx];

    setCurrentPage(0);
    setIndex(0);
    setLeads([]);
    setListIdIdx(newViewIdx);
    getListLeads(listId, input, statut, itemParPage, 0, false, departement, minAge, maxAge, jobCategory);
    setMaxPageFetched(0);
  }

  useEffect(() => {
    setup();
  }, [auth, listsIds]);

  const isUptodateVersion = (auth) => {
    if (!auth || !auth.version) {
      return true;
    }

    return auth.version === Version;
  }

  useEffect(() => {
    if (!wasAuthenticatedOnce) {
      return;
    }

    let isUptoDate = isUptodateVersion(auth);

    if (!isUptoDate) {
      setConfirmation({
        "text": "La version de Lead Access que vous utilisez n'est plus à jour, veuillez rafraîchir la page.",
        "showYesButton": false,
        "showNoButton": false,
        "specialPopup": null,
        "confirmCallback": () => { }
      });
    }
  }, [auth, wasAuthenticatedOnce]);

  const showExternalToolsButton = () => {
    return true
  }

  const handleShowExternalTools = () => {
    setDisplayExternalTool(true);
  }

  const handlePagesModeClickPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  }

  const handlePagesModeClickNextPage = (nextPageIdx) => {
    setCurrentPage(nextPageIdx);
    fetchPageIfNeedBe(nextPageIdx);
  }

  const getLeadsNavigationArrowsComponents = () => {
    if (unique) {
      return <div className="la_holder-arrows">
        {index > 0 ? <div key="previousLead" className="la_arrows w-inline-block" onClick={() => setIndex(index - 1)} >
          <img src="./images/arrow-filled.svg" loading="lazy" alt="" className="la_image_arrow left" />
        </div> : null}
        {index < (leads.length - 1) ? <div className="la_arrows w-inline-block" onClick={() => setIndex(index + 1)}>
          <img src="./images/arrow-filled.svg" loading="lazy" alt="" className="la_image_arrow" />
        </div> : null}
      </div>;
    } else {
      const isLastPageFull = leads.length === (maxPageFetched + 1) * itemParPage;
      const areThereLeadsLeft = isLastPageFull || currentPage !== maxPageFetched;
      return <div className="la_holder-arrows">
        {currentPage > 0 ? <div key="previousLead"
          className="la_arrows w-inline-block"
          onClick={handlePagesModeClickPreviousPage} >
          <img src="./images/arrow-filled.svg" loading="lazy" alt="" className="la_image_arrow left" />
        </div> : null}
        {areThereLeadsLeft ? <div className="la_arrows w-inline-block"
          onClick={() => handlePagesModeClickNextPage(currentPage + 1)}>
          <img src="./images/arrow-filled.svg" loading="lazy" alt="" className="la_image_arrow" />
        </div> : null}
      </div>;
    }
  }

  const shouldDisplayLists = () => {
    return auth && allLists !== null && getAccessibleListsIds().length >= 2;
  }

  const getListsTabsComponent = () => {
    if (!shouldDisplayLists()) {
      return null;
    }

    const buttons = getAccessibleListsIds().map((listId, nextIdx) => {
      const isListSelected = listIdIdx === nextIdx;
      let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

      const classSuffix = isListSelected ? " selected" : "";
      return <div key={listId} onClick={() => handleListIdChange(nextIdx)} className={`la_b2b w-inline-block${classSuffix}`}>
        <div className="la_text-b2b">{list.name}</div>
      </div>;
    });

    return <div className="la_b2b_b2c">
      {buttons}
    </div>;
  }

  const getListsAndArrowsComponent = () => {
    const displayLists = shouldDisplayLists();

    const classSuffix = displayLists ? "" : " only_arrows";
    return <div className={`la_holder-b2b-b2c_arrows${classSuffix}`}>
      {getListsTabsComponent()}
      {getLeadsNavigationArrowsComponents()}
    </div>;
  }

  const getBottomPagesNavigationComponent = () => {
    const isLastPageFull = leads.length === (maxPageFetched + 1) * itemParPage;
    const areThereLeadsLeft = isLastPageFull || currentPage !== maxPageFetched;

    const maxIndexAddition = areThereLeadsLeft ? 1 : 0;
    let maxIndex = Math.min(maxPageFetched + maxIndexAddition, currentPage + 2);
    const minIndex = Math.max(maxIndex - 4, 0);
    // increase maxIndex if more can be added to the right
    if (maxIndex - minIndex < 4 && maxIndex < maxPageFetched) {
      maxIndex = Math.min(maxPageFetched + maxIndexAddition, minIndex + 4);
    }
    const pagesIndexes = getIntegersInRange(minIndex, maxIndex);

    return <div key="bottom_pages_nav" className="la_holder-pagination">
      {pagesIndexes.map(pageIdx => {
        console.log("currentPage", currentPage);
        const isClicked = currentPage === pageIdx;
        const classesAdditionnalElement = isClicked ? "clicked" : "";

        const onclickFunc = () => {
          if (pageIdx === currentPage) {
            return;
          } else {
            handlePagesModeClickNextPage(pageIdx);
          }
        }

        return <div key={pageIdx}
          className={`la_button-pagination ${classesAdditionnalElement} w-inline-block`}
          onClick={onclickFunc}>
          <div className={`la_numbers-pagination ${classesAdditionnalElement}`}>{pageIdx + 1}</div>
        </div>;
      })}
    </div>
  }

  const getMainComponent = () => {
    if (!auth) {
      return null;
    }

    if (allLists === null) {
      return null;
    }

    return <div>
      {unique && leads[index] &&
        <div key="leads_unique" className='la_column_section'>
          <div className='la_lead-access-holder'>
            <Lead key={leads[index].id}
              lead={leads[index]}
              list={list}
              setConfirmation={setConfirmation}
              handleShowExternalTools={handleShowExternalTools}
              showExternalToolsButton={showExternalToolsButton()}
              setExternalToolLead={setExternalToolLead}
              setPopupCreateReminderLeadId={setPopupCreateReminderLeadId}
              reminders={reminders ? reminders.filter(re => re.lead_id === leads[index].id) : []}
              setToast={setToast} />
          </div>
        </div>}
      {!unique && <div key="leads_all" className='la_column_section'>
        <div className='la_lead-access-holder'>
          {
            leads && leads?.slice(currentPage * itemParPage, (currentPage + 1) * itemParPage).map((lead) => {
              const leadReminders = reminders ? reminders.filter(re => re.lead_id === lead.id) : [];
              return <Lead key={lead.id}
                lead={lead}
                list={list}
                setConfirmation={setConfirmation}
                handleShowExternalTools={handleShowExternalTools}
                showExternalToolsButton={showExternalToolsButton()}
                setExternalToolLead={setExternalToolLead}
                setPopupCreateReminderLeadId={setPopupCreateReminderLeadId}
                reminders={leadReminders}
                setToast={setToast} />;
            })
          }
          {getBottomPagesNavigationComponent()}
        </div>
      </div>
      }
    </div>;
  }

  const closeFilters = () => {
    setExpandFilters(false);
  }

  const openFilters = () => {
    storeFeatureUsage(auth.id, "lead_view_show_filters", null);
    setExpandFilters(true);
  }

  const getFiltersComponent = (list) => {
    if (!expandFilters) {
      return null;
    }

    let displayUserAgeFilters = true; // todo(1) adapt to b2b now just works for company age
    let displayDepartementFilter = true;
    let displayJobFilter = false;

    if (list && (list.nickname === ListsNicknames["B2B_MEDICAL"] || list.nickname === '__all_company_leads__')) {
      displayJobFilter = true;
    }

    return <div className="la_form-filter-pop-up">
      <div className="w-form">
        <div className="la_form-6">
          {displayUserAgeFilters ? <div key="age" className="la_horizontal-inputs">
            <div className="la_holder-input">
              <label className="la_field-label">Age Min.</label>
              <input className="la_text-field-3 w-input"
                maxLength={8}
                placeholder=""
                type="text"
                value={minAge}
                onChange={(e) => handleMinAgeFilterChange(e)} />

            </div>
            <div className="la_holder-input right">
              <label className="la_field-label">Age Max.</label>
              <input className="la_text-field-3 w-input"
                maxLength={8}
                placeholder=""
                type="text"
                value={maxAge}
                onChange={(e) => handleMaxAgeFilterChange(e)} />
            </div>
          </div> : null}

          {displayDepartementFilter ? <div key="departement" className="la_horizontal-inputs">
            <div className="la_holder-input">
              <label className="la_field-label">Département</label>
              <input className="la_text-field-3 _130 w-input"
                maxLength={256}
                placeholder=""
                type="text"
                value={departement}
                onChange={(e) => handleDepartementFilterChange(e)} />
            </div>
          </div> : null}
          {displayJobFilter ? <select key="job_category" value={jobCategory} onChange={handleJobCategoryChange} className="la_la_filter-dropdown _2 w-select">
            <option value="__undefined__">Tous les métiers</option>
            {jobCategories.map((cat) => {
              return <option key={cat.id} value={cat.id}>{cat.name}</option>;
            })}
          </select> : null}
        </div>
      </div>
    </div>;
  }

  function getStatsComponent() {
    const statsElements = [];
    for (const nextStatutKey in LeadStatus) {
      const nextStatut = LeadStatus[nextStatutKey];
      const nextLeadsCount = statutCount?.filter((s) => s.statut === nextStatut)?.[0]?.count_statut || "0";

      const color = StatusColor[nextStatut];
      const classNameSuffix = color ? ` ${color}` : "";
      statsElements.push(<div key={nextStatut} className={`la_stats-model ${classNameSuffix}`} >
        <div className="la_status-title">{nextStatut}</div>
        <div className="la_status-number">{nextLeadsCount}</div>
      </div>);
    }

    return (
      <div className='la_column_section'>
        <div className="la_lead-stats">
          <div className="la_center">
            <h1 className="la_number-leads"><span className="la_text-span">{totalCount}</span> Leads</h1>
          </div>
          <div className="la_stats-leads">
            {statsElements}
          </div>
        </div>
      </div>
    );
  }

  function getMetricsComponent() {
    return null;
    // add a key="metrics"    
    {/* <div className="la_holder-metrics">
          <div className="la_holder-elements-metrics">
            <div className="la_title-metrics">Nombre de rendez-vous</div>
            <div className="la_holder-icon-number">
              <img src="./images/calendar-svgrepo-com-1-1.svg" loading="lazy" alt="" className="la_icon-metrics" />
              <div className="la_holder-emoji-number">
                <div className="la_holder-emojis">
                  <img src="../images/happy-emoji-svgrepo-com-1.svg" loading="lazy" alt="" className="happy-emoji" />
                  <img src="../images/confused-emoji-svgrepo-com-1.svg" loading="lazy" alt="" className="stable-emoji" />
                  <img src="../images/angry-emoji-svgrepo-com-1.svg" loading="lazy" alt="" className="angry-emoji" />
                </div>
                <div className="la_number-calendar">12</div>
              </div>
              <div className="la_holder-tuto">
                <div className="la_holder-tutotxt">?</div>
              </div>
            </div>
            <div className="la_holder-text-tuto">
              <div className="la_title-tuto">Nombre de rendez-vous</div>
              <div className="la_text-tuto">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.</div>
            </div>
          </div>
          <div className="la_holder-elements-metrics">
            <div className="la_title-metrics">Objectif de la journée </div>
            <div className="la_holder-icon-number">
              <img src="../images/goal-2-svgrepo-com-1.svg" loading="lazy" width="30" alt="" className="la_icon-metrics" />
              <div className="la_holder-emoji-number">
                <img src="../images/check-mark-button-svgrepo-com-1.svg" loading="lazy" alt="" className="la_image-10" />
                <div className="number-flag">12</div>
              </div>
              <div className="la_holder-tuto">
                <div className="la_holder-tutotxt">?</div>
              </div>
            </div>
            <div className="la_holder-text-tuto _2">
              <div className="la_title-tuto">Objectif de la journée</div>
              <div className="la_text-tuto">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.</div>
            </div>
          </div>
          <div className="la_holder-elements-metrics last">
            <div className="la_title-metrics">Top du classement</div>
            <div className="la_holder-icon-number">
              <img src="../images/cup-champion-svgrepo-com-1.svg" loading="lazy" alt="" className="la_icon-metrics" />
              <div className="la_holder-emoji-number">
                <img src="../images/Frame-154.svg" loading="lazy" alt="" className="la_image-10 last" />
                <div className="la_number-achievements">12</div>
              </div>
              <div className="la_holder-tuto">
                <div className="la_holder-tutotxt">?</div>
              </div>
            </div>
            <div className="la_holder-text-tuto _3rd">
              <div className="la_title-tuto">Objectif de la journée</div>
              <div className="la_text-tuto">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.</div>
            </div>
          </div>
        </div> */}
  }

  function getStickyComponent() {
    const stickyPartClassSuffix = isPreprod ? " preprod" : "";
    const uniqueViewToggleClassSuffix = unique ? " selected" : "";
    return (
      <div className={`la_sticky-div-nav${stickyPartClassSuffix}`}>
        <div className='la_column_section'>
          {getMetricsComponent()}
          <div key="search_and_filter" className="la_holder-nav-bar-leads">
            <div className="la_nav-search-filtres">
              <div className="la_toggle-search">
                <div className={`la_toggle-button${uniqueViewToggleClassSuffix}`} onClick={() => setUnique(!unique)}>
                  <div className="la_holder-toggles">
                    <img src="./images/Toggle-vert.svg" loading="lazy" alt="" className="la_icon-in-toggle" />
                  </div>
                </div>
                <div className="la_search">
                  {/* <form action="/search" className="la_search-2 w-form"> */}
                  <div className="la_search-2 w-form">
                    <input className="la_search-input w-input"
                      maxLength={256}
                      placeholder="Rechercher"
                      type="search"
                      required={true}
                      onChange={(e) => handleSearchChange(e)} />
                    <input type="submit" className="la_search-button w-button" value="" />
                  </div>
                  {/* </form> */}
                  <img src="./images/search-svgrepo-com-1-1.svg" loading="lazy" alt="" className="la_icon-search" />
                </div>
              </div>
              <div className="la_filters">
                <div className="la_form-filter">
                  <div className='la_icon-filter_clickable' onClick={() => expandFilters ? closeFilters() : openFilters()}>
                    <img src="./images/filter-svgrepo-com-1-1.svg" loading="lazy" alt="" className="la_icon-filter" />
                  </div>
                  {getFiltersComponent(list)}
                </div>
                <div className="la_filters-dropdown w-form">
                  {/* <form id="email-form-6" name="email-form-6" data-name="Email Form 6" method="get" className="la_form-4" data-wf-page-id="6718ea3e363cd731f5106618" data-wf-element-id="7a3d3b71-6488-7af1-3955-275037472597"> */}
                  <div className="la_form-4">
                    <select className="la_la_filter-dropdown w-select"
                      value={statut ? statut : 'DEFAULT'}
                      onChange={handleStatusChange}>
                      <option key="default" value="DEFAULT" disabled>Choisir un statut</option>
                      {Object.values(LeadStatus).map((l, i) => {
                        return <option key={i} value={l}>{l}</option>
                      })}
                    </select>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
            {getListsAndArrowsComponent()}
          </div>
        </div>
      </div>
    );
  }

  let listId = getAccessibleListsIds()[listIdIdx];
  let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

  return (
    <div className="la_body">
      <section className="la_section-6">
        {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
        {popupCreateReminderLeadId ? <PopupAddReminder key={popupCreateReminderLeadId}
          leadId={popupCreateReminderLeadId}
          setToast={setToast}
          successCallback={() => reloadReminders(auth)}
          closingCallback={() => setPopupCreateReminderLeadId(null)}
        /> : null}
        <Popup />
        {displayExternalTool && <ExternalToolsSelection handleClose={() => setDisplayExternalTool(false)} lead={externalToolLead} />}
        {confirmation !== null && <ConfirmNotification text={confirmation.text}
          confirmCallback={confirmation.confirmCallback}
          setConfirmation={setConfirmation}
          color={color}
          showYesButton={confirmation.showYesButton}
          showNoButton={confirmation.showNoButton}
          specialPopup={confirmation.specialPopup}
          setToast={setToast} />}
        <Header />
        {getStatsComponent()}
        {getStickyComponent()}
        {getMainComponent()}
      </section>
    </div>
  );
}

export default MainPage;
