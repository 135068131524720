import React, { useState } from "react";
import Header from "./component/header";
import TestChatbot from "./component/metrics/test_chatbot";
import Toast from "./component/ux/toast";

export default function TestChatbotPage() {

    const [toast, setToast] = useState(null);

    return (
        <div className="la_body">
            <section className="la_section-6">
                {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
                <Header />
                <div className="la_column_section">
                    <div className="old_body">
                        <TestChatbot setToast={setToast} />
                    </div>
                </div>
            </section>
        </div>
    );
}
