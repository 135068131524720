import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import { logout } from '../api/axios';


// todo(1) statut sans statut definitvement enlever les null et mettre not null dans db et default value
// todo(1) , ne plus manager color secondary color etc....
export default function Header() {
    const { auth, setAuth, logoURL, isPreprod } = useContext(AuthContext);

    const [showUserMenu, setShowUserMenu] = useState(false);

    const handleLogout = () => {
        logout().then(() => setAuth({}));
    }

    const getMenuComponent = () => {
        if (!auth?.email) {
            return null;
        }

        const hasAdminPage = auth && auth.email && auth.user_type === "Manager";
        const hasFormationPage = auth && auth.has_formation_page === true;

        const menus = ["leads"];
        if (hasFormationPage) {
            menus.push("formation");
        }
        if (hasAdminPage) {
            menus.push("admin");
        }

        if (menus.length === 1) {
            return null;
        }

        const menusComponents = menus.map(menu => {
            if (menu === "leads") {
                return <Link key={menu} to={'/'} className="la_link-page w-inline-block">
                    <img src="./images/home-alt-svgrepo-com-1.svg" loading="lazy" alt="" />
                </Link>;
            } else if (menu === "formation") {
                return <Link key={menu} to={'/formation'} className="la_link-page w-inline-block">
                    <img src="./images/archive-svgrepo-com-1.svg" loading="lazy" alt="" />
                </Link>
            } else if (menu === "admin") {
                return <Link key={menu} to={'/admin'} className="la_link-page w-inline-block">
                    <img src="./images/apps-svgrepo-com-1.svg" loading="lazy" alt="" />
                </Link>
            } else {
                return null;
            }
        });

        return <div className="la_holder-links-pages">
            {menusComponents}
        </div>
    }

    const getProfileComponent = () => {
        if (!auth?.email) {
            return null;
        }

        return <div className="la_relative-holder-user-profile">
            <div className="la_profile-user w-inline-block" onClick={() => setShowUserMenu(prevValue => !prevValue)}>
                <img src="/images/Frame-1-1.svg" loading="lazy" alt="" className="la_user-image" />
                <div className="la_name-user">{auth.firstname}</div>
            </div>
            {showUserMenu ? <div className="la_holder-buttons-user">
                {/* <div className="la_button-3 w-button">Mes préferences</div> */}
                <div className="la_button-4 w-button" onClick={handleLogout}>Déconnexion</div>
            </div> : null}
        </div>;
    }

    const navbarSuffix = isPreprod ? " preprod" : "";
    const spaceSuffix = isPreprod ? " preprod_version" : "";
    return (
        <>
            {isPreprod ? <div className="la_indicateur-version">
                <div className="la_text-block-51">Version preprod</div>
            </div> : null}
            <div className={`la_nav-bar-top${navbarSuffix}`}>
                <Link to='/'>
                    <img src={logoURL} loading="lazy" alt="" />
                </Link>
                {getMenuComponent()}
                {getProfileComponent()}
            </div>
            <div className={`la_nav-lead-access${spaceSuffix}`}></div>
        </>
    );
}
