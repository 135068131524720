import axios from 'axios';
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./component/header";
import AuthContext from "./context/AuthProvider";
import { getBackendHost } from './lib/config';

import Toast from './component/ux/toast';
import "./style/Login.css";


export default function Login() {
    const nav = useNavigate()
    const { auth, setAuth, color, secondaryColor } = useContext(AuthContext);
    const [values, setValues] = useState({ email: "", password: "" });
    const [toast, setToast] = useState(null);

    const URL = `${getBackendHost()}/v1/la/`;

    const axiosInstance = axios.create({
        baseURL: URL
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { email, password } = values
            const response = await axiosInstance.post('login',
                JSON.stringify({ email, password }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response) {
                if (response.data.is_active === true) {
                    setAuth(response.data);
                    nav('/');
                } else {
                    setAuth({});
                    setToast({ text: "Votre compte a été désactivé.", severity: "error" });
                }
            }
        } catch (err) {
            setToast({ text: "Erreur de connection, veuillez réessayer puis vérifier votre mot de passe.", severity: "error" });
            // todo(1) gerer tous ces messages d'erreur
            // if (!err?.response) {
            //     setErrMsg('No Server Response');
            // } else if (err.response?.status === 400) {
            //     setErrMsg('Missing Username or Password');
            // } else if (err.response?.status === 401) {
            //     setErrMsg('Unauthorized');
            // } else {
            //     setErrMsg('Login Failed');
            // }
        }
    }

    const updateEmail = (newEmail) => {
        let cleanedEmail = newEmail.toLowerCase();

        // exception cause one mail was created with an upper letter
        if (cleanedEmail === "i.seridi@bnvce.fr") {
            cleanedEmail = "I.seridi@bnvce.fr"
        }

        setValues({ ...values, email: cleanedEmail });
    }

    return (
        <div className="la_body">
            <section className="la_section-6">
                <div className="register">
                    {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
                    <Header />
                    <div className="form" style={{ borderColor: color }}>
                        <div className="form-block">
                            <p className="label">Email</p>
                            <input style={{ borderColor: color, backgroundColor: secondaryColor }} value={values.email} onChange={e => updateEmail(e.target.value)} type="email" id="email" name="email" placeholder="Email" />
                        </div>
                        <div className="form-block">
                            <p className="label">Mot de passe</p>
                            <input style={{ borderColor: color, backgroundColor: secondaryColor }} onChange={e => { setValues({ ...values, password: e.target.value }) }} type="password" id="password" name="password" placeholder="Mot de passe" />
                        </div>
                        <input className="submit" style={{ backgroundColor: color }} type="button" value="Se connecter" onClick={handleSubmit} />
                    </div>
                </div>
            </section>
        </div>
    );
}